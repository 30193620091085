<template>
    <div class="page">
        <router-view/>
    </div>
</template>

<script>

export default {
    name:"task",
  
}
</script>

<style scoped lang="less">

.page{
    width: 750px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f7f8fa;
    overflow-x: hidden;
}
</style>